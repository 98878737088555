<template>
  <el-form-item prop="no_input_prompt_text" :label="title">
    <div v-if="subTitle" class="tooltips" v-html="subTitle"></div>
    <input-variable-popper
      :value="inputVal"
      is-content-editable
      @input="updateText('no_input_prompt_text', $event)"
      :is-text-area="false"
      include-prompts
      include-audio-variables
      :include-secure-variables="false"
      include-payments
      :ats="getOptionsInitiators"
      popper-class="prompt-node-popper"
      class="promptEditor"
      :scroll-ref="scrollRef"
    />
    <audio-player
      class="audio-player"
      :disabled="!inputVal"
      @get-preview="
        generateAudio(
          'inputVal',
          'generatingAudioForPrompt',
          'promptAudioFile',
          'promptAtAudioFileCreation'
        )
      "
      :show-reload="promptContentChanged"
      :generating-audio="generatingAudio"
      :file="promptAudioFile"
    />
  </el-form-item>
</template>

<script>
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  components: {
    InputVariablePopper,
    AudioPlayer
  },
  data() {
    return {
      promptAudioFile: "",
      generatingAudio: false,
      promptAtAudioFileCreation: "",
      task_id: this.taskId
    };
  },
  mixins: [GenerateAudio],
  props: {
    /**
     * Value passed from the caller view
     */
    value: {
      type: String,
      required: true
    },

    scrollRef: {
      required: true,
      type: String
    },

    /**
     * The title of the prompt
     */
    title: {
      type: String,
      required: true
    },

    /**
     * Pattern to match to enable variable selector
     */
    getOptionsInitiators: {
      type: Array,
      default() {
        return ["{{", "[["];
      }
    },

    /**
     * The current task ID
     */
    taskId: {
      type: Number,
      required: true
    },

    /**
     * Subtitle of the prompt input
     */
    subTitle: {
      type: String,
      default: ""
    }
  },

  computed: {
    /**
     * Getter and setter for the input value
     */
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    /**
     * Audio player loading status
     */
    promptContentChanged() {
      return this.inputVal !== this.promptAtAudioFileCreation;
    }
  },

  methods: {
    /**
     * Update the input text from the
     */
    updateText: function(key, value) {
      this.inputVal = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
  }
}
</style>
