<template>
  <div>
    <el-row>
      <div>
        <el-col :span="24">
          <el-form-item :label="__('No Input Timeout')">
            <span class="asr-description">
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the prompt finishes to when the system returns a no input result as it has been unable to detect the caller’s speech.") }}
            </span>
            <el-input-number
              id="asrNoInputTimeOut"
              v-model="no_input_timeout"
              @change="handleChange('no_input_timeout', $event)"
              :min="100"
              :step="50"
              size="medium"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row>
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Speech Complete Timeout')">
            <!-- Show related information based on the ASR settings -->
            <span
              v-if="
                showAdvancedAsrSettings('single_utterance') &&
                  showAdvancedAsrSettings('inter_result_timeout')
              "
              class="asr-description"
            >
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the caller stops talking to when the system initiates an end-of-speech event. It should be longer than the inter result timeout to prevent overlaps. To customize, disable Single Utterance.") }}
            </span>
            <span
              v-else-if="showAdvancedAsrSettings('inter_result_timeout')"
              class="asr-description"
            >
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the caller stops talking to when the system initiates an end-of-speech event. It should be longer than the inter result timeout to prevent overlaps.") }}
            </span>
            <span v-else class="asr-description">
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the caller stops talking to when the system initiates an end-of-speech event.") }}
            </span>
            <el-tooltip
              placement="right"
              effect="dark"
              :content="
                __('Cannot be changed when single utterance is turned on')
              "
              :disabled="!disableSpeechCompleteAndInterim"
            >
              <el-input-number
                id="asrSpeechCompleteTimeout"
                v-model="speech_complete_timeout"
                @change="handleChange('speech_complete_timeout', $event)"
                :min="completeSpeechTimeoutMinValue"
                :step="50"
                size="medium"
                controls-position="right"
                :disabled="disableSpeechCompleteAndInterim"
              ></el-input-number>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row>
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Speech Incomplete Timeout')">
            <span class="asr-description">
              <!-- eslint-disable-next-line -->
              {{__("Wait time, in milliseconds, from when incoherent background noise begins and continues uninterrupted to when the system initiates an end-of-speech event.") }}
            </span>
            <el-input-number
              id="asrSpeechIncompleteTimeout"
              v-model="speech_incomplete_timeout"
              @change="handleChange('speech_incomplete_timeout', $event)"
              :min="100"
              :step="50"
              size="medium"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row
      v-show="showAdvancedAsrSettings('speech_start_timeout')"
      ref="speech_start_timeout"
    >
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Speech Start Timeout')">
            <span class="asr-description">
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the prompt starts to play to when the system begins to listen for caller input. This is similar to the scenario where barge in is enabled.")}}
            </span>
            <el-input-number
              id="asrSpeechStartTimeout"
              v-model="speech_start_timeout"
              @change="handleChange('speech_start_timeout', $event)"
              :min="0"
              :step="50"
              size="medium"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row v-show="showAdvancedAsrSettings('inter_result_timeout')">
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Inter Result Timeout')">
            <span class="asr-description">
              <!-- eslint-disable-next-line -->
              {{ __("Wait time, in milliseconds, from when the caller stops talking to when the system initiates an end-of-speech event as it has been unable to detect interim results. It should be shorter than the Speech Complete Timeout. Set from 500ms to 3000ms based on the maximum pause time in the caller response. To customize, disable Single Utterance.") }}
            </span>
            <el-tooltip
              placement="right"
              effect="dark"
              :content="
                __('Cannot be changed when single utterance is turned on')
              "
              :disabled="!disableSpeechCompleteAndInterim"
            >
              <el-input-number
                v-model="inter_result_timeout"
                @change="handleChange('inter_result_timeout', $event)"
                :min="interResultTimeoutMinValue"
                :step="50"
                size="medium"
                controls-position="right"
                :disabled="disableSpeechCompleteAndInterim"
                id="asrInterResultTimeout"
              ></el-input-number>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row ref="barge_in_sensitivity">
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Barge In Sensitivity')">
            <span class="asr-description">
              <!-- eslint-disable-next-line -->
              {{ __("If Barge In is enabled, how sensitive the recognizer should be to user utterances.") }}
            </span>
            <el-input-number
              v-model="barge_in_sensitivity"
              @change="handleChange('barge_in_sensitivity', $event)"
              :precision="2"
              :step="0.01"
              :min="0"
              :max="1"
              size="medium"
              controls-position="right"
              id="asrBargeInSensitivity"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row v-show="showAdvancedAsrSettings('auto_punctuation')">
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Auto Punctuation')">
            <span class="asr-description">{{
              __("Add punctuation automatically to caller speech.")
            }}</span>
            <el-checkbox
              v-model="auto_punctuation"
              style="padding-left: 5px"
              id="asrAutoPunctuation"
            ></el-checkbox>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row v-show="showAdvancedAsrSettings('profanity_filter')">
      <div>
        <el-col :span="24">
          <el-form-item
            :label="__('Profanity Filter')"
            v-show="this.provider === 'google'"
          >
            <span class="asr-description">{{
              __("Automatically remove profanities from recognized speech.")
            }}</span>
            <el-checkbox
              v-model="profanity_filter"
              style="padding-left: 5px"
              id="asrProfanityFilter"
            ></el-checkbox>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
    <el-row v-show="showAdvancedAsrSettings('single_utterance')">
      <div>
        <el-col :span="24">
          <el-form-item :label="__('Single Utterance')">
            <span class="asr-description">{{
              __("Recognize only a single utterance from the speech.")
            }}</span>
            <el-checkbox
              v-model="single_utterance"
              style="padding-left: 5px"
              id="asrSingleUtteranceCheckbox"
            ></el-checkbox>
          </el-form-item>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "AdvancedSpeechParameters",
  components: {},
  props: {
    nlpEngine: {
      required: false,
      type: String,
      default: "Custom"
    },
    formTypeId: {
      required: false,
      type: String
    },
    speechCompleteTimeout: {
      required: false,
      type: Number
    },
    speechIncompleteTimeout: {
      required: false,
      type: Number
    },
    noInputTimeout: {
      type: Number,
      required: false
    },
    speechStartTimeout: {
      type: Number,
      required: false
    },
    interResultTimeout: {
      type: Number,
      required: false
    },
    bargeInSensitivity: {
      type: Number,
      required: false
    },
    autoPunctuation: {
      type: Boolean,
      required: false
    },
    profanityFilter: {
      type: Boolean,
      required: false
    },
    singleUtterance: {
      type: Boolean,
      required: false,
      default: true
    },
    nodeType: {
      type: String,
      required: false,
      default: ""
    },
    provider: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      speech_complete_timeout: 0,
      speech_incomplete_timeout: 0,
      no_input_timeout: 0,
      speech_start_timeout: 0,
      inter_result_timeout: 0,
      barge_in_sensitivity: 0,
      default_values: {
        speech_complete_timeout: 1000,
        speech_incomplete_timeout: 20000,
        no_input_timeout: 5000,
        speech_start_timeout: 50,
        inter_result_timeout: 0,
        barge_in_sensitivity: 0.5
      }
    };
  },
  computed: {
    auto_punctuation: {
      get: function() {
        return this.autoPunctuation;
      },
      set: function(newValue) {
        this.$emit("update-nlp-parameter", "auto_punctuation", newValue);
        this.$emit("handle-advanced-asr-settings-changed");
      }
    },
    profanity_filter: {
      get: function() {
        return this.profanityFilter;
      },
      set: function(newValue) {
        this.$emit("update-nlp-parameter", "profanity_filter", newValue);
        this.$emit("handle-advanced-asr-settings-changed");
      }
    },

    single_utterance: {
      get: function() {
        return this.singleUtterance;
      },
      set: function(newValue) {
        //if the nlpEngine is dialogflow or the node type is cloud stt node / open form node,
        //then when the single-utterance is true, will reset speech complete timeout and interim result timeout to the default value.
        //When single-utterance is false, will set the interim result timeout to have the same value as speech complete timeout has
        if (this.applyRestrictions) {
          if (!newValue) {
            this.inter_result_timeout = this.speech_complete_timeout;
            this.$emit(
              "update-nlp-parameter",
              "inter_result_timeout",
              this.inter_result_timeout
            );
          } else {
            this.inter_result_timeout = this.default_values[
              "inter_result_timeout"
            ];
            this.$emit(
              "update-nlp-parameter",
              "inter_result_timeout",
              this.inter_result_timeout
            );
            this.speech_complete_timeout = this.default_values[
              "speech_complete_timeout"
            ];
            this.$emit(
              "update-nlp-parameter",
              "speech_complete_timeout",
              this.speech_complete_timeout
            );
          }
        }
        this.$emit("update-nlp-parameter", "single_utterance", newValue);
        this.$emit("handle-advanced-asr-settings-changed");
      }
    },
    disableSpeechCompleteAndInterim() {
      return this.applyRestrictions && this.single_utterance;
    },
    completeSpeechTimeoutMinValue() {
      return this.applyRestrictions ? this.inter_result_timeout : 100;
    },
    applyRestrictions() {
      //only using dialogflow as nlp engine or cloud stt node or open form node with Custom form and Google as Provider will be applied restrictions
      return (
        this.nlpEngine === "dialogflow" ||
        this.nlpEngine === "dialogflow_cx" ||
        this.nodeType === "cloudSTTNode" ||
        (this.nodeType === "openFormNode" && this.nlpEngine === "Custom")
      );
    },
    interResultTimeoutMinValue() {
      // If single utterance is turned on, inter result timeout is disabled(0), otherwise it must be greater than 0
      // Use 50 as the step size is 50
      return this.single_utterance ? 0 : 50;
    }
  },
  methods: {
    handleChange(nlp_param, value) {
      this.$emit("handle-advanced-asr-settings-changed");
      // console.log("nlp component", nlp_param, value);
      // if (value !== false && value !== 0 && !value) {
      //   // console.log("updating to default");
      //   value = this.default_values[nlp_param];
      // }
      this.$emit(
        "update-nlp-parameter",
        nlp_param,
        value,
        this.default_values[nlp_param]
      );
    },
    showAdvancedAsrSettings(asr_type) {
      if (this.provider !== "lumenvox" && this.nodeType !== "menuNode") {
        switch (asr_type) {
          case "single_utterance":
            return (
              this.nlpEngine === "Custom" ||
              this.nlpEngine === "dialogflow" ||
              this.nlpEngine === "dialogflow_cx"
            );
          case "profanity_filter":
            return this.nlpEngine === "Custom";
          case "auto_punctuation":
            return this.nlpEngine === "Custom";
          case "inter_result_timeout":
            return (
              this.nlpEngine === "Custom" ||
              this.nlpEngine === "dialogflow" ||
              this.nlpEngine === "dialogflow_cx" ||
              this.nlpEngine === "ibm_watson"
            );
          default:
            return true;
        }
      } else {
        return false;
      }
    },
    setControlProperties() {
      // console.log("set control properties");
      this.speech_complete_timeout = this.speechCompleteTimeout;
      this.speech_incomplete_timeout = this.speechIncompleteTimeout;
      this.no_input_timeout = this.noInputTimeout;
      this.speech_start_timeout = this.speechStartTimeout;
      this.inter_result_timeout = this.interResultTimeout;
      this.barge_in_sensitivity = this.bargeInSensitivity;
    },
    resetSpeechCompleteTimeout(newValue) {
      this.speech_complete_timeout = newValue;
      this.handleChange(
        "speech_complete_timeout",
        this.speech_complete_timeout
      );
    }
  },
  mounted() {
    this.setControlProperties();
  },
  watch: {
    formTypeId: function() {
      // console.log("form type id changed", this.formTypeId);
      this.setControlProperties();
    },
    speech_complete_timeout: function() {
      this.$nextTick(() => {
        if (this.speech_complete_timeout !== 0 && !this.speech_complete_timeout)
          this.speech_complete_timeout = this.speechCompleteTimeout;
      });
    },
    speech_incomplete_timeout: function() {
      this.$nextTick(() => {
        if (
          this.speech_incomplete_timeout !== 0 &&
          !this.speech_incomplete_timeout
        )
          this.speech_incomplete_timeout = this.speechIncompleteTimeout;
      });
    },
    no_input_timeout: function() {
      this.$nextTick(() => {
        if (this.no_input_timeout !== 0 && !this.no_input_timeout)
          this.no_input_timeout = this.noInputTimeout;
      });
    },
    speech_start_timeout: function() {
      this.$nextTick(() => {
        if (this.speech_start_timeout !== 0 && !this.speech_start_timeout)
          this.speech_start_timeout = this.speechStartTimeout;
      });
    },
    inter_result_timeout: function() {
      this.$nextTick(() => {
        if (this.inter_result_timeout !== 0 && !this.inter_result_timeout)
          this.inter_result_timeout = this.interResultTimeout;
        //The speech complete timeout should be always greater than or equal to the interim result timeout value
        if (this.applyRestrictions) {
          if (this.speech_complete_timeout < this.inter_result_timeout)
            this.resetSpeechCompleteTimeout(this.inter_result_timeout);
        }
      });
    },
    barge_in_sensitivity: function() {
      this.$nextTick(() => {
        if (this.barge_in_sensitivity !== 0 && !this.barge_in_sensitivity)
          this.barge_in_sensitivity = this.bargeInSensitivity;
      });
    },
    nlpEngine: function() {
      this.$nextTick(() => {
        if (this.applyRestrictions) {
          if (this.speech_complete_timeout < this.inter_result_timeout)
            this.resetSpeechCompleteTimeout(this.inter_result_timeout);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-row {
  //margin-top: 10px;
  //margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.asr-description {
  display: block;
  text-align: left;
  word-wrap: normal;
  word-break: keep-all;
  line-height: 24px;
}
</style>
