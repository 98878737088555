<template>
  <div class="tooltips">
    {{ description }}
  </div>
</template>

<script>
/**
 * Key-value pair for the confirmation condition description
 */
const CONDITION_DESCRIPTION = {
  get required() {
    return __("Always confirm input with the user.");
  },
  get speechRecogConf() {
    // eslint-disable-next-line
    return __("Confirm input with the user only if the speech recognizer confidence is below the threshold.");
  },
  get notRequired() {
    return __("Default behavior. Never confirm input with the user.");
  }
};

export default {
  data: function() {
    return {};
  },
  props: ["value"],
  computed: {
    /**
     * Get the description for the currently selected condition type
     * @returns {string} The description for the currently selected condition type
     */
    description: function() {
      try {
        return CONDITION_DESCRIPTION[this.value];
      } catch (e) {
        return "";
      }
    }
  }
};
</script>
