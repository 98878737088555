<template>
  <div>
    <el-form-item :label="__('Confirmation Condition')">
      <el-select
        style="width: 100%;"
        :placeholder="__('Please select a variable')"
        v-model="inputVal"
        default-first-option
      >
        <el-option
          v-for="(item, index) in CONFIRMATION_CONDITIONS"
          :label="__(item.label)"
          :value="item.value"
          :key="index"
        >
          <div>
            {{ __(item.label) }}
          </div>
        </el-option>
      </el-select>
      <description :value="inputVal" />
    </el-form-item>
  </div>
</template>

<script>
import Description from "./ConfirmationConditionDescription.vue";

/**
 * Net required condition value
 */
const NOT_REQUIRED_CONDITION = "notRequired";

/**
 * Speech recognizer condition value
 */
const SPEECH_RECOGNIZER_CONDITION = "speechRecogConf";

/**
 * The supported conditions
 */
const CONFIRMATION_CONDITIONS = [
  {
    get label() {
      return __("Required");
    },
    value: "required"
  },
  {
    get label() {
      return __("Speech Recognizer Confidence");
    },
    value: SPEECH_RECOGNIZER_CONDITION
  },
  {
    get label() {
      return __("Not Required");
    },
    value: NOT_REQUIRED_CONDITION,
    default: true
  }
];

export default {
  /**
   * The default confirmation condition
   */
  DEFAULT_CONDITION: CONFIRMATION_CONDITIONS.filter(
    condition => condition.default !== undefined
  )
    .map(condition => condition.value)
    .pop(),

  /**
   * Value for the "Not Required" condition
   */
  NOT_REQUIRED_CONDITION,

  /**
   * Value for the "Speech Recognizer Confidence" condition
   */
  SPEECH_RECOGNIZER_CONDITION,

  components: {
    Description
  },
  data: function() {
    return {
      CONFIRMATION_CONDITIONS
    };
  },

  props: [
    /**
     * Value passed from the caller view
     */
    "value"
  ],

  computed: {
    /**
     * Getter and setter for the input value
     */
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
