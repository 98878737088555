<template>
  <div>
    <confirmation-condition v-model="confirmationCondition" />

    <div v-if="confirmationIsRequired">
      <el-form-item
        prop="confidence_level"
        :label="__('Confidence Level (%)')"
        v-if="confirmationCondition === 'speechRecogConf'"
      >
        <div class="tooltips">
          <!-- eslint-disable-next-line -->
          {{ __("Anything less than this defined value will trigger confirmation.") }}
        </div>
        <el-input-number
          v-model="targetConfidence"
          :precision="0"
          :min="1"
          :step="1"
          :max="99"
          :step-strictly="false"
        ></el-input-number>
      </el-form-item>

      <el-form-item prop="allowBargeIn" :label="__('Speech Controls')">
        <el-checkbox v-model="allowBargeIn" style="margin-left: 2px;"
          >{{ __("Barge In") }}
        </el-checkbox>
      </el-form-item>
      <confirm-prompt
        v-model="promptText"
        :title="__('Confirmation Prompt')"
        :subTitle="confirmationPromptSubTitle"
        :taskId="taskId"
        :scroll-ref="scrollRef"
      />

      <confirm-prompt
        v-model="noInputPromptText"
        :title="__('No Input Prompt')"
        :taskId="taskId"
        :scroll-ref="scrollRef"
      />

      <confirm-prompt
        v-model="noMatchPromptText"
        :title="__('No Match Prompt')"
        :taskId="taskId"
        :scroll-ref="scrollRef"
      />

      <div>
        <el-form-item
          prop="no_max_number_of_input_count"
          :label="__('Max Number of Attempts to Confirm')"
        >
          <el-input-number
            v-model="confirmationMaxNumberOfAttemptsCount"
            :min="0"
            :step="1"
            :max="9"
            :step-strictly="true"
            controls-position="right"
            size="small"
            @change="updateMaxNumberOfAttemptsCount"
          ></el-input-number>
        </el-form-item>
      </div>

      <div v-if="showNoInputTimeout">
        <el-row>
          <div>
            <el-col :span="18">
              <el-form-item :label="__('No Input Timeout')">
                <span
                  style="display: block;text-align: left; word-wrap: normal; word-break: keep-all"
                >
                  <!-- eslint-disable-next-line -->
                  {{ __("Time in milliseconds within which a caller should start speaking or the recognizer returns no input result.") }}
                </span>
                <el-input-number
                  v-model="confirmationNoInputTimeout"
                  :min="100"
                  :step="50"
                  size="medium"
                  controls-position="right"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationCondition from "@/views/build/callflow/components/audioInputConfirmation/components/ConfirmationCondition.vue";
import ConfirmPrompt from "./components/ConfirmationPrompt.vue";
import _ from "lodash";

/**
 * Default prompt text
 */
const DEFAULT_PROMPT_TEXT =
  "You entered: [user_input]. Say Yes or press 1 if this is correct. Otherwise say No or press 2";

/**
 * Default prompt text for invalid input
 */
const DEFAULT_INVALID_INPUT_PROMPT = "Incorrect Input";

/**
 * Default prompt text for no input
 */
const DEFAULT_NO_INPUT_PROMPT = "I cannot hear you";

/**
 * Default timeout for no input
 */
const DEFAULT_NO_INPUT_TIMEOUT = 5000;

/**
 * Default max number of attempts count input
 */
const DEFAULT_MAX_NUMBER_OF_ATTEMPTS_COUNT = 5;

const DEFAULT_ALLOW_BARGE_IN = false;

const DEFAULT_CONFIDENCE_TARGET = 0.5;

export default {
  default_value: {
    confirmation_prompt: DEFAULT_PROMPT_TEXT,
    confirmation_condition: ConfirmationCondition.DEFAULT_CONDITION,
    confirmation_no_input: DEFAULT_NO_INPUT_PROMPT,
    confirmation_no_match: DEFAULT_INVALID_INPUT_PROMPT,
    confirmation_max_attempts: DEFAULT_MAX_NUMBER_OF_ATTEMPTS_COUNT,
    no_input_timeout: DEFAULT_NO_INPUT_TIMEOUT,
    confirmation_allow_barge_in: DEFAULT_ALLOW_BARGE_IN,
    confirmation_target_confidence: DEFAULT_CONFIDENCE_TARGET
  },
  components: {
    ConfirmationCondition,
    ConfirmPrompt
  },
  data: function() {
    return {
      confirmationCondition: ConfirmationCondition.DEFAULT_CONDITION
    };
  },

  props: {
    /**
     * The current task ID
     */
    taskId: {
      type: Number,
      required: true
    },
    maxNumberOfAttemptsCount: {
      type: Number,
      required: false
    },
    showNoInputTimeout: {
      type: Boolean,
      required: false
    },
    noInputTimeout: {
      type: Number,
      required: false
    },

    /**
     * The object that contains the NLP parameters
     */
    documentRule: {
      type: Object,
      required: true
    },

    /**
     * Props for scroll reference. Must be a valid HTML selector
     */
    scrollRef: {
      required: true,
      type: String
    }
  },
  methods: {
    /**
     * Emit event for updating NLP parameters
     */
    handleChange(nlp_param, value) {
      this.$emit(
        "update-nlp-parameter",
        nlp_param,
        value,
        this.default_values[nlp_param]
      );
    },

    /**
     * Emit update event for maximum number of attempts
     */
    updateMaxNumberOfAttemptsCount(value) {
      this.$emit("update-max-number-of-attempts-count", value);
    },

    /**
     * When value is null or undefined, default will be returned
     *
     * @param value The value to check if null or undefined
     * @param defaultIfNull Default value if value is null
     */
    defaultOnNull(value, defaultIfNull) {
      if (value === null || value === undefined) {
        return defaultIfNull;
      }

      return value;
    }
  },

  computed: {
    /**
     * Check if the selected confirmation condition does not need further configuration
     * @returns {boolean} Returns true if the selected confirmation condition does not require config
     */
    confirmationIsRequired: function() {
      return (
        this.confirmationCondition !==
        ConfirmationCondition.NOT_REQUIRED_CONDITION
      );
    },

    /**
     * Getter/setter fir the prompt text
     */
    promptText: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_prompt",
            DEFAULT_PROMPT_TEXT
          ),
          DEFAULT_PROMPT_TEXT
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_prompt", val);
      }
    },

    /**
     * Getter/setter for the confirmation condition
     */
    confirmation_condition: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_condition",
            ConfirmationCondition.DEFAULT_CONDITION
          ),
          ConfirmationCondition.DEFAULT_CONDITION
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_condition", val);
      }
    },

    /**
     * Getter/setter for the no input prompt text
     */
    noInputPromptText: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_no_input",
            DEFAULT_NO_INPUT_PROMPT
          ),
          DEFAULT_NO_INPUT_PROMPT
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_no_input", val);
      }
    },

    /**
     * Getter/setter for the no match prompt text
     */
    noMatchPromptText: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_no_match",
            DEFAULT_INVALID_INPUT_PROMPT
          ),
          DEFAULT_INVALID_INPUT_PROMPT
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_no_match", val);
      }
    },

    /**
     * Getter/setter for the confirmation no input timeout
     */
    confirmationNoInputTimeout: {
      get: function() {
        const confirmationNoInputTimeout = _.get(
          this.documentRule,
          "data.confirmation_no_input_timeout",
          null
        );

        if (Number.isFinite(confirmationNoInputTimeout)) {
          return confirmationNoInputTimeout;
        }

        // Fallback
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.no_input_timeout",
            DEFAULT_NO_INPUT_TIMEOUT
          ),
          DEFAULT_NO_INPUT_TIMEOUT
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_no_input_timeout", val);
      }
    },

    /**
     * Getter/setter for the confirmation max number of attempts count
     */
    confirmationMaxNumberOfAttemptsCount: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_max_attempts",
            DEFAULT_MAX_NUMBER_OF_ATTEMPTS_COUNT
          ),
          DEFAULT_MAX_NUMBER_OF_ATTEMPTS_COUNT
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_max_attempts", val);
      }
    },

    /**
     * Getter/setter for allow barge in
     */
    allowBargeIn: {
      get: function() {
        return this.defaultOnNull(
          _.get(
            this.documentRule,
            "data.confirmation_allow_barge_in",
            DEFAULT_ALLOW_BARGE_IN
          ),
          DEFAULT_ALLOW_BARGE_IN
        );
      },
      set: function(val) {
        this.$set(this.documentRule.data, "confirmation_allow_barge_in", val);
      }
    },

    /**
     * Getter/setter for target confidence
     */
    targetConfidence: {
      // We must perform percentage conversion. We are storing this as a decimal value between 0 and 1 in the backend.

      get: function() {
        return (
          this.defaultOnNull(
            _.get(
              this.documentRule,
              "data.confirmation_target_confidence",
              DEFAULT_CONFIDENCE_TARGET
            ),
            DEFAULT_CONFIDENCE_TARGET
          ) * 100
        );
      },
      set: function(val) {
        this.$set(
          this.documentRule.data,
          "confirmation_target_confidence",
          val / 100
        );
      }
    },

    /**
     * get the sub title for confirmation prompt
     * @returns {string}
     */
    confirmationPromptSubTitle() {
      // eslint-disable-next-line
      return `<code>[user_input]</code> ${__('will be replaced by the first return value (if there are multiple return value).')}`;
    }
  },

  watch: {
    confirmation_max_attempts: function() {
      this.$nextTick(() => {
        if (
          this.confirmation_max_attempts !== 0 &&
          !this.confirmation_max_attempts
        )
          this.confirmation_max_attempts = this.maxNumberOfAttemptsCount;
      });
    },
    confirmationCondition: function(newVal) {
      this.confirmation_condition = newVal;
    }
  },
  created() {
    this.confirmationCondition = this.confirmation_condition;

    // Make sure we have no nulls
    if (_.get(this.documentRule, "data.confirmation_prompt", null) === null) {
      this.promptText = DEFAULT_PROMPT_TEXT;
    }

    if (
      _.get(this.documentRule, "data.confirmation_condition", null) === null
    ) {
      this.confirmation_condition = ConfirmationCondition.DEFAULT_CONDITION;
    }

    if (_.get(this.documentRule, "data.confirmation_no_input", null) === null) {
      this.noInputPromptText = DEFAULT_NO_INPUT_PROMPT;
    }

    if (_.get(this.documentRule, "data.confirmation_no_match", null) === null) {
      this.noMatchPromptText = DEFAULT_INVALID_INPUT_PROMPT;
    }

    if (
      _.get(this.documentRule, "data.no_input_timeout", null) === null &&
      _.get(this.documentRule, "data.confirmation_no_input_timeout", null) ===
        null
    ) {
      this.confirmationNoInputTimeout = DEFAULT_NO_INPUT_TIMEOUT;
    }

    if (
      _.get(this.documentRule, "data.confirmation_max_attempts", null) === null
    ) {
      this.confirmationMaxNumberOfAttemptsCount = DEFAULT_MAX_NUMBER_OF_ATTEMPTS_COUNT;
    }

    if (
      _.get(this.documentRule, "data.confirmation_allow_barge_in", null) ===
      null
    ) {
      this.allowBargeIn = DEFAULT_ALLOW_BARGE_IN;
    }
  }
};
</script>
